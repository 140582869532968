import { View } from "react-native"
import { VideoLink } from "../../../contrib/zdf/link"
import { Center, HorizBox, Pad, PadBox } from "np-platform-client/component/basics"
import { Heading, Paragraph, UtilityText } from "np-platform-client/component/text"
import { useCollection, useDatastore, useObject, usePersonaKey } from "np-platform-client/util/datastore"
import React, { useEffect, useState } from "react"
import { getPrettyTimestamp } from "../../../system/videotime"
import { colorBlack, colorTextGrey } from "np-platform-client/component/color"
import { ProfilePhoto } from "np-platform-client/component/people"
import { ColorLegend, CountScale } from "../../../contrib/zdf/videovoting/videovotingBaseElements"
import { PercentageBarContainer, PercentageSegment } from "../../../contrib/zdf/videovoting/videovotingPercentageBars"
import { QuestionResultIndividual } from "../../../contrib/zdf/videovoting/VideoVotingOverviews"
import { AccordionField } from "np-platform-client/component/form"
import { REPLACE_ZDF_AccordionField } from "../../../component/zdf/form"

/*
Figma: https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-5&t=5DeU2AZDvMXzjf7G-0
*/

export const VideoVotingOverviewFeature = {
    name: 'Related Polls',
    key: 'votingoverview',
    config: {
        tabs: [{
            id: "tab-voting",
            label: "Votings",
            component: VideoVotingOverview
        }]
    }
}

export function VideoVotingOverview() {

    const [votingData, setVotingData] = useState(undefined);
    const datastore = useDatastore();

    const getVideoVotingTemplate = async () => {
        setVotingData(
            await datastore.callServerAsync('videovoting', 'getVideoVotingTemplate')
        );
    }

    useEffect(()=>{
        if(!votingData) {
            getVideoVotingTemplate();  
        }
    },[])

    if (!votingData) {
        return <View>
            <UtilityText label="No voting data available." />
        </View>
    }
    else {
        return <View>
            {votingData.map((votingDataEntry, idx) => <VotingResults key={idx} votingDataEntry={votingDataEntry.data} votingInstanceKey={votingDataEntry.votingInstanceKey} />)}
        </View>
    }
}

export function VotingResults({ votingDataEntry, votingInstanceKey }) {

    const backlink_article = useObject("backlink_article", votingInstanceKey);

    return (
        <PadBox horiz={20}>
            <Pad size={5} />
            <VideoLink
                videoTitle={backlink_article?.title}
                // TODO: The correct video URL should be set through backlink_article.
                videoUrl={
                    votingDataEntry.questions[0].type === "connected"
                        ? "https://zdf.talkb.org/dokumentation/37-grad/37-wie-wir-lieben-wollen-100.html"
                        : "https://zdf.talkb.org/dokumentation/37-grad/endometriose-schmerzen-100.html"
                }
                thumbnailUrl={backlink_article?.image}
            />
            <Pad size={10} />
            <View>
            {votingDataEntry.questions.map((question, idx) => (
                    <View key={question.key}>
                        <REPLACE_ZDF_AccordionField
                            nameInLog={question.text}
                            titleContent={
                                <PadBox horiz={16}>
                                    <UtilityText weight='medium' text={question.text} />
                                </PadBox>
                            }
                            testId={question.text}
                        >
                            <PadBox vert={16} left={16} right={16 * 3}>
                                <QuestionResults question={question} votingInstanceKey={votingInstanceKey} />
                            </PadBox>
                        </REPLACE_ZDF_AccordionField>
                        <Pad size={10} />
                    </View>
                ))}
            </View>
            <Pad size={35} />
        </PadBox>
    );
}


export function QuestionResults({ question, votingInstanceKey }) {

    let publicVotes = useCollection("derived_votes");
    // Filter out revoked votes
    publicVotes = publicVotes.filter(vote => (vote.optionKey));
    const publicVotesFiltered = publicVotes.filter(vote => (vote.questionKey === question.key && vote.instanceKey === votingInstanceKey));

    if(question.type === "connected") {
        return <QuestionResultConnected 
                question={question}
                publicVotes={publicVotes}
                showTotalVotes
                noHeadline>
            </QuestionResultConnected>
    }
    else {
        return <QuestionResultIndividual allVotes={publicVotesFiltered} question={question}/>
    }
}

export function filterVotingDataForInstance(allVotings, votingInstanceKey) {
    return allVotings.filter(vote => (vote.instanceKey === votingInstanceKey && vote.optionKey));
}

export function filterVotingDataForPersona(allVotings, personaKey) {
    return allVotings.filter(vote => (vote.from === personaKey));
}

export function filterVotingDataForQuestion(allVotings, questionKey) {
    return allVotings.filter(vote => (vote.questionKey === questionKey));
}

export function filterVotingDataForOption(allVotings, optionKey) {
    return allVotings.filter(vote => (vote.optionKey === optionKey));
}

export function getConnectedQuestionStats(allVotes, question, personaKey) {
    const subQuestionData = {}
    let totalVoteCount = 0;
    let questionWithMostVotes = {count: 0}

    question.subQuestions.forEach((subQuestion) => {
        const subQuestionVotesFiltered = filterVotingDataForQuestion(allVotes, subQuestion.key);
        const subQuestionVotesPersonal = filterVotingDataForPersona(subQuestionVotesFiltered, personaKey);
        totalVoteCount += subQuestionVotesFiltered.length

        subQuestionData[subQuestion.key] = {
            key: subQuestion.key,
            votingsAll: subQuestionVotesFiltered,
            personalVote: subQuestionVotesPersonal?.[0],
            count: subQuestionVotesFiltered.length
        }

        if(questionWithMostVotes?.count < subQuestionData[subQuestion.key].count){
            questionWithMostVotes = subQuestionData[subQuestion.key]
        }
    })

    subQuestionData.totalAmount = totalVoteCount

    return {
        subQuestionData,
        totalVoteCount,
        questionWithMostVotes
    }
}

export function QuestionResultConnected({
    question, 
    noHeadline = false,
    absolute = false,
    showScale = false,
    showTotalVotes = false,
    showIndividualVotes = false,
    publicVotes
}) {

    const personaKey = usePersonaKey();

    const personalVotes = publicVotes.filter((vote) => (vote.from === personaKey));

    // Filter out revoked votes
    publicVotes = publicVotes.filter(vote => (vote.optionKey));

    const connectedQuestionData = getConnectedQuestionStats(publicVotes, question, personaKey)

    return <View style={{ gap: 24 }}>

        {noHeadline ?
            <></>
            : <View style={{ gap: 16 }}>
                <Heading label="Voting Results"></Heading>
                <Paragraph text={question.text}></Paragraph>
            </View>
        }
        
        {showScale && <HorizBox>
            <View style={{flex: 1}}>
                <CountScale maxValue={connectedQuestionData.questionWithMostVotes.count}></CountScale>
            </View>
           {showTotalVotes && <View style={{width: 56}}></View>} 
        </HorizBox>}
        <View style={{gap: 32}}>
            <View style={{gap: 16}}>
                {question.subQuestions.map((subQuestion) =>(
                    <React.Fragment key={subQuestion.key}>
                        <QuestionResultsConnectedSubQuestionPercentageBar
                            key={subQuestion.key}
                            question={question} 
                            subQuestion={subQuestion} 
                            allVotes={publicVotes}
                            personalVotes={personalVotes}
                            maxCount={connectedQuestionData.questionWithMostVotes.count}
                            absolute={absolute}
                            showIndividualVotes ={showIndividualVotes}
                            showTotalVotes={showTotalVotes}
                            >
                        </QuestionResultsConnectedSubQuestionPercentageBar>
                    </React.Fragment> 
                ))}
            </View>
            <ColorLegend labels={question.options.map(o=>o.text)} colors={connectedQuestionColorPalette}></ColorLegend>
        </View>
        
    </View>
    
    
}

const connectedQuestionColorPalette = ["#A1A5FF", "#716FE8", "#5A1EF5"]
export function QuestionResultsConnectedSubQuestionPercentageBar({question, subQuestion, allVotes, personalVotes, maxCount, absolute=false, showIndividualVotes=true, showTotalVotes}) {

    let allVotesFiltered = allVotes.filter(vote=>(vote.questionKey === subQuestion.key && vote.optionkey!== null));
    let personalVote = personalVotes.find(vote=>((vote.questionKey === subQuestion.key) && vote.optionKey))
    let allVotesCount = allVotesFiltered.length;
    const emptyVote = allVotesFiltered.length === 0
    

    let barPercentage = absolute ? allVotesCount*100/(maxCount!==0 ? maxCount : 1) : 100;

    let optionCountArray = []
    question.options.forEach(option => {
        const optionInfo = {}
        optionInfo.count = (allVotesFiltered.filter(vote=>(vote.optionKey === option.key)).length)
        optionInfo.percentage = optionInfo.count*100/allVotesCount
        optionCountArray.push(optionInfo)
    })

    return <View>
    <HorizBox spread center>
        <View style={{width: 56, overflow:"hidden"}}>
            <QuestionTimeStampLabel question={subQuestion} color={colorTextGrey}>
               <UtilityText /> 
            </QuestionTimeStampLabel>
        </View> 
        <View style={{flex: 1}}>
            <PercentageBarContainer style={{height: 24, overflow:"hidden",
                width: maxCount ? barPercentage+"%" : "100%"
            }}>
            {   !emptyVote &&
                question.options.map((q, i) => {
                    return <PercentageSegment key={i} percentage={optionCountArray[i].percentage} color={connectedQuestionColorPalette[i]}>
                        {personalVote?.optionKey === q.key ? <ProfilePhoto type="tiny" userId={personalVote.from}/> : <></>}
                    </PercentageSegment>
                })
            }
            </PercentageBarContainer>  
        </View>

        {showTotalVotes && <View style={{width: 56}}>
            <Center>
                <UtilityText label={allVotesCount.toString()}></UtilityText>  
            </Center>  
        </View>}
              
    </HorizBox>
    {
        showIndividualVotes &&
        <HorizBox>
            <View style={{width: 56}}/>
            <View style={{flex: 1}}>
                <PercentageBarContainer style={{width: maxCount ? barPercentage+"%" : "100%"}}>
                    {!emptyVote &&
                        question.options.map((option, i)=>{
                            const renderEmptyVote = emptyVote && i == 0;
                            const percentage = !renderEmptyVote ? optionCountArray[i].percentage : 100
                            // console.log(optionCountArray[i].count)
                            return <PercentageSegment key={i} percentage={percentage}>
                                <UtilityText text={optionCountArray[i].count.toString()} color={colorTextGrey}></UtilityText>  
                            </PercentageSegment>
                        })
                    }  
                </PercentageBarContainer>  
            </View>
                      
        </HorizBox>
    }
  </View>  
}

export function QuestionTimeStampLabel({children, question, color}) {

    let label = getPrettyTimestamp(question.timestamp, true)
    let colorOut = color
    if(question.initial) label = "START"
    if(question.final) label = "END"
    if(question.final || question.initial) colorOut=colorBlack

    return <View style={{flex: 1}}>
        {React.cloneElement(children, {label: label, strong: (question.final || question.initial) ? true : false, color: colorOut})}
    </View>
}