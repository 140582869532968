import { ConversationScreen, FlowBox, Pad } from "np-platform-client/component/basics"
import { CTAButton } from "np-platform-client/component/button"
import { FormField } from "np-platform-client/component/form";
import { Heading, TextField } from "np-platform-client/component/text"
import { useDatastore, useSiloKey } from "np-platform-client/util/datastore";
import { useState } from "react";

export const PuppetSupportFeature = {
    name: 'Puppet Test Support',
    key: 'puppettest',
    subscreens: {
        puppetTools: PuppetToolsScreen,
    }
}

function PuppetToolsScreen() {
    const [puppetSecret, setPuppetSecret] = useState('');
    const siloKey = useSiloKey();
    const datastore = useDatastore();

    if (siloKey !== 'puppet-test') {
        return null;
    }

    async function onLogin(testUserKey) {
        const {loginToken} = await datastore.callServerAsync('puppet', 'login', {
            puppetSecret, testUserKey
        });
        console.log('loginToken', loginToken);
        if (!loginToken) {
            throw new Error('Login call failed');
        }
        await datastore.signInWithTokenAsync(loginToken);
    }

    async function onReset() {
        await datastore.callServerAsync('puppet', 'reset', {puppetKey: puppetSecret});
    }

    return <ConversationScreen pad>
        <Pad />
        <Heading type='large' weight='strong' label='Puppet Test Tools' />
        <Pad />
        <FormField label='Puppet Secret'>
            <TextField testID='puppet-secret' placeholder='Enter puppet secret' value={puppetSecret} onChange={setPuppetSecret} />
        </FormField>
        <Pad />
        <FlowBox rowGap={10} columnGap={10}>
            <CTAButton label='Login as User A' onPress={() => onLogin('a')} />
            <CTAButton label='Login as User B' onPress={() => onLogin('b')} />
            <CTAButton label='Login as User C' onPress={() => onLogin('c')} />
        </FlowBox>

        <Pad />
        <CTAButton label='Reset test silo' onPress={() => onReset()} />
    </ConversationScreen>
}