import { PadBox } from "np-platform-client/component/basics";
import { colorGreyBorder, colorLightBlueBackground, colorTextBlue } from "np-platform-client/component/color";
import { UtilityText } from "np-platform-client/component/text";
import { View, StyleSheet } from "react-native";

export function REPLACE_CBC_Tag({label, emoji, text, type='emphasized', strong=false, formatParams, color=null, textColor=null, onPress, compact=false}) {
    const s = TagStyle;
    return <View style={[
                s.button, 
                type == 'emphasized' && s.emphasized, 
                type == 'tiny' && s.tiny,      
                color && {borderColor: color, backgroundColor: color},
                compact && {height: 28}
            ]} 
            hoverStyle={s.hover} onPress={onPress}>
        {emoji && <PadBox right={6}><UtilityText text={emoji} type='tiny' strong /></PadBox>}
        <UtilityText  
            label={label} text={text} formatParams={formatParams} 
            strong={strong} type='tiny' />
    </View>
}
const TagStyle = StyleSheet.create({
    button: {
        alignSelf: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 12,
        paddingVertical: 6,
        borderWidth: 1,
        borderRadius: 4,
        height: 32,
        // borderColor: 'red',
        borderColor: colorGreyBorder
    },
    emphasized: {
        borderRadius: 100,
    },
    tiny: {
        paddingHorizontal: 4,
        paddingVertical: 2,
        borderRadius: 100,
        height: 20,
        backgroundColor: colorLightBlueBackground,
        borderColor: colorLightBlueBackground
    }
})