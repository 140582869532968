import React from "react";

import {CommentsInput} from "np-platform-client/component/comment";
import {Paragraph} from "np-platform-client/component/text";
import {useGlobalProperty} from "np-platform-client/util/datastore";
import {colorWhite} from "np-platform-client/component/color";

import {AskMeAnythingLiveStatus, TopicDate} from "../../../component/cbcrc/header";
import {formatToDateRangeString} from "../../../component/cbcrc/date";
import {Pad, PadBox, spacings} from "np-platform-client/component/basics";



export const AskMeAnythingHeaderFeature = {
    key: 'amaheader',
    name: 'Ask me anything header',
    config: {
        topicHeaderLeftWidgets: [AskMeAnythingLiveStatusWidget],
        topicDescriptionWidget: AskMeAnythingStatusDescription,
        topicHeaderBottomWidgets: [AskMeAnythingHeaderBottomWidget],
    }
}

function AskMeAnythingLiveStatusWidget() {
    const status = useGlobalProperty('status');
    const live = status === 'live';

    return <AskMeAnythingLiveStatus live={live} />
}

function AskMeAnythingStatusDescription() {
    return null;
}

function AskMeAnythingHeaderBottomWidget() {

    const status = useGlobalProperty('status');
    const live = status === 'live';
    return live ?
        <PadBox vert={spacings.xl}>
            <CommentsInput />
            <Pad size={spacings.sm} />
            <Paragraph color={colorWhite} label='Your question will be considered for future content pieces. Please note, we may not be able to answer all questions.' />
        </PadBox>
        :
        <PadBox vert={spacings.xl}>
            <TopicDate date={formatToDateRangeString({start: new Date(startAt), end: new Date(endAt), locale: 'fr-CA'})} />
        </PadBox>
}
