import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { hideVideoOverlay, showVideoOverlay } from '../../system/videotime';

/**
 * @typedef {Object} DimensionData
 * @property {string} width - The desired width of the overlay (e.g., "200px").
 * @property {string} height - The desired height of the overlay (e.g., "150px").
 */


/**
 * @typedef {Object} PositionData
 * @property {string} [left] - The desired left position of the overlay (e.g., "10px"). Optional.
 * @property {string} [top] - The desired top position of the overlay (e.g., "5px"). Optional.
 * @property {string} [right] - The desired right position of the overlay (e.g., "calc(100vw - 200px)"). Optional.
 * @property {string} [bottom] - The desired bottom position of the overlay (e.g., "10px"). Optional.
 */

/** This component will cause the overlay over the main page's video to be completely invisible. The content beneath will be completely accessible
 * When this component is dismounted the vide overlay becomes visible and interactive again.
*/
export function HiddenOverlay({children}) {
    useEffect(() => {
        // Startup: Hide the overlay over the main video
        window.parent.postMessage({ type: 'psi-change-video-overlay-visibility' , visible: false}, '*');
        return () => {
            window.parent.postMessage({ type: 'psi-change-video-overlay-visibility' , visible: true}, '*');
        }
    },[])
    return <>{children}</>
}

export function OverlayScreen({children,
        position, 
        dimension,
        repositionIframe=false,
        showBackdrop=false
    }) {

    // Keep track of the size of the children
    const [dimensionContent, setDimensionContent] = useState({width: "0px", height: null})

    useEffect(() => {
        showVideoOverlay()
        return () => {
            hideVideoOverlay();
        }
    }, [])

    const calculateDimension = () => {
        const dimData = {...dimension};
        if(!dimData.height) {
            dimData.height = dimensionContent.height
        }
        return dimData
    }

    function onLayout(e) {
        const {height, width} = e.nativeEvent.layout;
        // console.log("OverlayScreen height:", height);
        setDimensionContent({height: height + "px", width: width + "px"})
    }

    useEffect(() => {
        window.parent.postMessage({type: 'psi-teaser-position', position: position}, '*');
    }, [position])


    useEffect(() => {
        window.parent.postMessage({type: 'psi-teaser-dimension', dimension: calculateDimension()}, '*');
    }, [dimension, dimensionContent])

    useEffect(() => {
        if(!dimension?.height) {
            setDimensionContent({})
        }
    }, [dimension])

    useEffect(()=>{
        if(!repositionIframe) return
        if(showBackdrop){
            window.parent.postMessage({ type: 'psi-show-video-backdrop'}, '*');
        } else {
            window.parent.postMessage({ type: 'psi-hide-video-backdrop'}, '*');
        }
    },[showBackdrop])

    return (
        <>
            {showBackdrop && !repositionIframe && (
                <View
                    style={{
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "black",
                        opacity: 0.2,
                    }}
                ></View>
            )}
            <View
                onLayout={onLayout}
                style={
                    repositionIframe
                        ? {}
                        : { position: "absolute", ...position, ...calculateDimension(), pointerEvents: "auto" }
                }
            >
                {children}
            </View>
        </>
    );
}