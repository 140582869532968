import { useGlobalProperty } from "np-platform-client/util/datastore"
import { PollWidget } from "../poll/poll";
import { UtilityText } from "np-platform-client/component/text";
import { PadBox, spacings } from "np-platform-client/component/basics";

export const TopicPollFeature = {
    key: 'topicpoll',
    name: 'Topic Poll',
    defaultConfig: {   
        topicTopWidgets: [],
        topicBottomWidgets: [] 
    }
}

export const TopicPollTopPositionFeature = {
    key: 'topictoppoll',
    name: 'Top Position',
    config: {
        topicTopWidgets: [TopicPoll],      
    }
}
export const TopicPollBottomPositionFeature = {
    key: 'topicbottompoll',
    name: 'Bottom Position',
    config: {
        topicBottomWidgets: [TopicPoll]        
    }
}

function TopicPoll() {
    const poll = useGlobalProperty('poll');
    if (!poll) return <UtilityText label='No poll defined' />;

    return <PadBox top={spacings.xl2} horiz={spacings.lg}>
        <PollWidget 
            pollKey='topicPoll' 
            question={poll.question}
            options={poll.options}
            pollType='MultipleChoice'
        />
    </PadBox>
}


export const NextTopicPollFeature = {
    key: 'nexttopicpoll',
    name: 'Next Topic Poll',
    config: {
        topicBottomWidgets: [NextTopicPoll]       
    }
}

function NextTopicPoll() {
    const nextTopicPoll = useGlobalProperty('nextTopicPoll');
    if (!nextTopicPoll) return <UtilityText label='No upcoming topic poll defined' />;

    return <PadBox top={spacings.xl2} horiz={spacings.lg}>
        <PollWidget
            pollKey={'upcomingTopicPoll'}
            question={nextTopicPoll.question}
            options={nextTopicPoll.options}
            pollType={'MultipleChoiceOther'}
            otherOptionValue={nextTopicPoll.otherOptionValue}
            otherOptionPlaceholder={nextTopicPoll.otherOptionPlaceholder}
        />
    </PadBox>
}
