import { TextField, UtilityText } from "np-platform-client/component/text";
import { getPrettyTimestamp, getVideoTime, usePlayedVideo, useVideoTime } from "../../system/videotime";
import { useCollection } from "np-platform-client/util/datastore";
import { TeaserScreen } from "../../system/teaser";
import { Tag } from "np-platform-client/component/button";
import { HorizBox, Narrow, PadBox } from "np-platform-client/component/basics";
import { Comment } from "np-platform-client/component/comment";

export const VideoSyncFeature = {
    name: 'Video Sync',
    key: 'videosync',
    config: {
        teaserScreen: VideoTeaserScreen,
        commentEditTopWidgets: [TimestampWidget],
        commentAboveWidgets: [TimestampTag],
        commentPostCheckers: [addTimestamp]
    }
}

function VideoTeaserScreen() {
    // const videoTime = useVideoTime();
    const {time: videoTime, url: videoUrl} = usePlayedVideo();
    const prettyTimestamp = getPrettyTimestamp(videoTime);
    const comments = useCollection('comment');    
    const distanceSorted = comments.sort((a, b) => Math.abs(a.videoTime - videoTime) - Math.abs(b.videoTime - videoTime));
    const closestComment = distanceSorted[0];
    return <TeaserScreen>
        <Narrow>
            <UtilityText type='tiny' label='Current Timestamp: {videoTime}' formatParams={{videoTime: prettyTimestamp}} />
            <UtilityText label='Video Url: {videoUrl}' formatParams={{videoUrl}} />
            {comments.length == 0 && <UtilityText weight='medium' type='tiny' label='No comments yet' />}
            {closestComment && 
                <PadBox vert={20}><Comment commentKey={closestComment.key} /></PadBox>
            }
        </Narrow>
    </TeaserScreen>    
}

function TimestampWidget({comment, setComment}) {
    const prettyTimestamp = getPrettyTimestamp(useVideoTime());

    return <PadBox bottom={10}><Tag label='Video Timestamp: {videoTime}' formatParams={{ videoTime: prettyTimestamp }} /></PadBox>
}

function TimestampTag({ comment }) {
    const prettyTimestamp = getPrettyTimestamp(comment.videoTime);

    return <PadBox bottom={10}><Tag label='Video Timestamp: {videoTime}' formatParams={{videoTime: prettyTimestamp}} /></PadBox>
}

function addTimestamp({ datastore, comment }) {
    return {
        allow: true,
        commentProps: {videoTime: getVideoTime()}
    }
}