import {useEffect} from 'react';
import { callServerApiAsync } from "np-platform-client/util/servercall";
import { signInWithTokenAsync } from 'np-platform-client/util/firebase';


const isInAndroid = (window) => !!window?.webkit?.messageHandlers;

const isInIOS = (window) => !!window?.Android;

const isInIFrame = (window) => window !== window.parent;

const useHydrateSessionFromParent = () => {
    useEffect(() => {
        const hydrateSessionTokenFn = async (ssoToken, provider) => {
            // const {loginToken} = await datastore.callServerAsync('auth', 'convertToken', {ssoToken, provider});
            const result = await callServerApiAsync({component: 'auth', funcname: 'convertToken', params: {
                ssoToken, provider
            }});

            console.log({result});

            if(result?.loginToken){
                console.log('signInWithToken');
                await signInWithTokenAsync(result.loginToken);
            }
        };

        const hydrateSessionListener = async (event) => {
            const {ssoToken, provider} = event?.detail;
            await hydrateSessionTokenFn(ssoToken, provider);
        }

        document.addEventListener("nativeMessageEvent", hydrateSessionListener);

        if(isInAndroid(window)) {
            window.Android.appReady();
        } else if(isInIOS(window)) {
            window.webkit.messageHandlers.appReady.postMessage('');
        } else if(isInIFrame(window)) {
            window.parent.postMessage('psi-app-ready', '*');
        }

        return () => {
            document.removeEventListener('nativeMessageEvent', hydrateSessionListener);
        };
    }, []);
};

export default useHydrateSessionFromParent;