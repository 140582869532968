import { useGlobalProperty } from "np-platform-client/util/datastore";
import { RecirculationSlider } from "../../../component/cbcrc/recirculationCards";
import { PadBox, Pad, spacings } from "np-platform-client/component/basics";
import { Heading } from "np-platform-client/component/text";


export const TopicRelatedContentFeature = {
    key: 'topicrelatedcontent',
    name: 'Related Content',
    config: {
        topicBottomWidgets: [RelatedContent]
    }
}


function RelatedContent() {
    const relatedContents = useGlobalProperty('relatedContents');

    return <PadBox top={spacings.xl2 * 2} horiz={spacings.lg}>
        <Heading type="large" weight="medium" label='Need more information to make up your mind?' />
        <Pad size={spacings.xl} />
        <RecirculationSlider cardsData={relatedContents} cardType='content' controls />
    </PadBox>
}


