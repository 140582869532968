import { ConversationScreen, HeaderBox, HorizBox, Pad, spacings } from "np-platform-client/component/basics";
import { colorTealBackground, colorWhite } from "np-platform-client/component/color";
import { Heading, UtilityText } from "np-platform-client/component/text";
import { useGlobalProperty } from "np-platform-client/util/datastore";
import { useConfig } from "np-platform-client/util/features";
import React from "react";


export const TopicStructure = {
    key: 'topic',
    name: 'Topic',
    screen: TopicScreen,
    teaser: TopicTeaser,
    defaultConfig: {
        topicDescriptionWidget: TopicDescription,
        topicContentWidgets: [],
        topicTopWidgets: [],
        topicBottomWidgets: [],
        topicHeaderLeftWidgets: [],
        topicHeaderRightWidgets: [],
        topicHeaderBottomWidgets: [],
        teaser: null,
        topBarHelpBubbles: [],
    }   
}

function TopicDescription() {
    return <UtilityText color={colorWhite} label='Discussion topic' />
}

export function TopicTeaser() {
    const {teaser} = useConfig();
    if (teaser) {
        return React.createElement(teaser);
    } else {
        return <UtilityText label='No teaser defined' />
    }
}

function TopicHeader() {
    const name = useGlobalProperty('name');
    const {topicHeaderLeftWidgets, topicHeaderRightWidgets, 
        topicHeaderBottomWidgets, topicDescriptionWidget} = useConfig();
    return <HeaderBox backgroundColor={colorTealBackground}>
        {(topicHeaderLeftWidgets?.length || topicHeaderRightWidgets?.length) ? <Pad size={12} /> : null}
        <HorizBox spread center>
            <HorizBox center>
                {topicHeaderLeftWidgets.map((Widget, index) => <Widget key={index} />)}
            </HorizBox>
            <HorizBox center>
                {topicHeaderRightWidgets.map((Widget, index) => <Widget key={index} />)}
            </HorizBox>
        </HorizBox>
        {(topicHeaderLeftWidgets?.length || topicHeaderRightWidgets?.length) ? <Pad size={spacings.xl} /> : <Pad size={12} />}
        {React.createElement(topicDescriptionWidget)}
        <Pad size={12} />
        <Heading color={colorWhite} type="large" weight="medium" label={name} />
        {topicHeaderBottomWidgets.map((Widget, index) => <Widget key={index} />)}
        <Pad size={12} />
    </HeaderBox>
}


function TopicScreen() {
    const {topicTopWidgets, topicContentWidgets, topicBottomWidgets} = useConfig();
    return <ConversationScreen>
        <TopicHeader />
        {topicTopWidgets.map((Widget, index) => <Widget key={index} />)}
        {topicContentWidgets.map((Widget, index) => <Widget key={index} />)}
        {topicBottomWidgets.map((Widget, index) => <Widget key={index} />)}
        <Pad size={80} />
    </ConversationScreen>
}


