import { View } from "react-native";
import { TextButton } from "np-platform-client/component/button";
import { gotoInstance } from "np-platform-client/util/navigate";
import { Card, HorizBox, Pad, PadBox } from "np-platform-client/component/basics";
import { ProfilePhoto } from "np-platform-client/component/people";
import { Heading, Paragraph, UtilityText } from "np-platform-client/component/text";
import { useDatastore, useDerivedCollection } from "np-platform-client/util/datastore";
import { formatMiniDate } from "np-platform-client/component/date";
import { Toggle } from "np-platform-client/component/form";
import { useEditableField, useProfileField } from "np-platform-client/structure/profile";
import { useIsAdmin } from "np-platform-client/component/admin";
import { colorTextGrey } from "np-platform-client/component/color";

export const ProfileAnswersFeature = {
    name: 'Conversation History',
    key: 'profileanswers',
    config: {
        profileModules: [{
            label: 'Conversation history', key: 'profileanswers',
            view: ViewProfileAnswers,
            edit: EditProfileAnswers,
            checkForErrors: () => null,
            makePreview: () => {},
        }],
        // profileWidgets: [ProfileAnswersWidget]
    }
}

export function DerivedAnswer({answer}) {
    const datastore = useDatastore();
    function onGotoQuestion() {
        gotoInstance({structureKey: 'question', instanceKey: answer.instanceKey});
    }
    const time = formatMiniDate(answer.time);
    return <PadBox top={20}>
        <Card>
            <HorizBox spread center>
                <HorizBox shrink>
                    <ProfilePhoto userId={datastore.getInstanceKey()} />
                    <Pad size={12} />
                    <View style={{flex: 1}}>
                        <TextButton type='small' text={answer.question} strong onPress={onGotoQuestion} />
                        <Pad size={4} />
                        {answer.inReview ? 
                            <UtilityText label='Your response is under review' />
                        : answer.violates ? 
                            <UtilityText label='Your response was rejected by a moderator' />
                        :
                            <Paragraph numberOfLines={1} text={answer.text} />
                        }   
                    </View>
                </HorizBox>
                <Pad size={12} />
                <UtilityText color='grey' text={time} />

            </HorizBox>
        </Card>
    </PadBox>
}

export function ViewProfileAnswers() {
    const answers = useDerivedCollection('answer');
    const isAdmin = useIsAdmin();
    const visible = useProfileField('answersVisible', true);

    if (!isAdmin && !visible) {
        return <PadBox top={20}>
            <Heading label='Conversation history' />
            <Pad />
             <UtilityText label='This user has not made their conversation history public.' />
        </PadBox>
    }

    return <PadBox top={20}>
        <Heading label='Conversation history' />
        {!visible && <PadBox top={8}><UtilityText color={colorTextGrey} label='Visible only to admins' /></PadBox>}
        <Pad size={8} />
        {answers.map(answer => <DerivedAnswer key={answer.key} answer={answer} />)}
    </PadBox>    
}

export function EditProfileAnswers() {
    const [visible, setVisible] = useEditableField('answersVisible', true);
    return <View>
        <Heading label='Conversation History' />
        <Pad size={12} />
        <Toggle label='Visible to others' value={visible} onChange={setVisible} />
        <Pad size={12} />
        <UtilityText 
            color={colorTextGrey}
            label='Your conversation history is always visible to admins and moderators.' />
    </View>
}