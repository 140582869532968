import { HorizBox, Pad, spacings } from "np-platform-client/component/basics";
import { Image, Pressable, ScrollView, StyleSheet, View } from "react-native";
import { Heading, UtilityText } from "np-platform-client/component/text";
import { PlayFilledAlt, Headphones, ChevronLeft, ChevronRight } from "@carbon/icons-react";
import { colorWhite, colorTextGrey } from "np-platform-client/component/color";
import { useRef } from "react";
import { FacePile } from "np-platform-client/component/people";
import { TextButton } from "np-platform-client/component/button";
import { formatToDateRangeString } from "./date";
import { TopicLiveStatus, TopicDate } from "./header";
import { useObject } from "np-platform-client/util/datastore";
import { gotoInstanceScreen } from "np-platform-client/util/navigate";
import { imagesCBCRC } from "../../contrib/cbcrc/assets";

export function TopicPromoCard({text, picture, cardSize = 'small', live, startAt, endAt, id}) {
    const s = createCardStyle({cardSize});
    const date = formatToDateRangeString({ start: new Date(startAt), end: new Date(endAt), locale: 'fr-CA'})

    return (
        <View style={s.card}>
            <View style={s.imageWrapper}>
                <Image style={s.image} source={{uri: picture ? picture : imagesCBCRC.imagePlaceholder}} />
            </View>
            <Pad size={spacings.lg} />
            {live && (
                <>
                    <TopicLiveStatus live={live} />
                    <Pad size={spacings.xs} />
                </>
            )}
            <Heading label={text} />
            <Pad size={spacings.lg} />
            <TopicDate date={date} />
        </View>
    )
}

export function ConversationPromoCard({text, picture, cardSize = 'small', id}) {
    const s = createCardStyle({cardSize});
    const questionPreview = useObject('derived_relatedQuestionPreview', id);
    const userList = questionPreview?.userList;
    const commentCount = questionPreview?.commentCount;
    const formatParams = {count: commentCount, singular: 'reply', plural: 'replies'};

    return (
        <Pressable style={s.card} onPress={() => {
            gotoInstanceScreen({structureKey: "question", instanceKey: id, params:{}})
            }}>
            <View style={s.imageWrapper}>
                <Image style={s.image} source={{uri: picture ? picture : imagesCBCRC.TEMPORARYrelatedConversation}} />
            </View>
            <Pad size={spacings.lg} />
            <Heading label={text} />
            <Pad size={spacings.lg} />
            {!!userList?.length && (
                <HorizBox spread center>
                    <FacePile type='tiny' userIdList={userList} />
                    <UtilityText label='{count} {noun}' formatParams={formatParams} type='small' color={colorTextGrey} />
                </HorizBox>
            )}
        </Pressable>
    )
}

function getContentCardIcon(type) {
    switch (type) {
        case 'audio':
            return  <Headphones width={16} height={16} />
        case 'media':
            return  <PlayFilledAlt width={16} height={16} />
        default:
            return null
    }
}

export function ContentPromoCard({text, picture, type, cardSize = 'small', url}) {
    const s = createCardStyle({cardSize});

    return (
        <Pressable style={s.card} onPress={() => {
            window.parent.postMessage({type: 'psi-navigate-to', url}, '*');
        }}>
            <View style={s.imageWrapper}>
                {(type && type !== 'text') && (
                    <View style={s.iconWrapper}>
                        {getContentCardIcon(type)}
                    </View>
                )}
                <Image style={s.image} source={{uri: picture ? picture : imagesCBCRC.imagePlaceholder}} />
            </View>
            <Pad size={spacings.lg} />
            <Heading label={text} />
        </Pressable>
    )
}

const createCardStyle = ({cardSize}) => StyleSheet.create({
    card: {
        width: cardSize === 'full' ? '100%' : cardSize === 'large' ? 335 : 250,
    },
    imageWrapper: {
        overflow: 'hidden',
        position: 'relative',
        height: cardSize === 'small' ? 150 : 200,
        borderRadius: 12,
    },
    image: {
        width: '100%',
        height: '100%',
    },
    iconWrapper: {
        zIndex: 2,
        position: 'absolute',
        bottom: 12,
        left: 12,
        justifyContent: 'center',
        alignItems: 'center',
        width: 24,
        height: 24,
        borderRadius: 1000,
        backgroundColor: colorWhite
    }
})

function getCardComponent(props) {
    switch (props.cardType) {
        case 'topic':
            return <TopicPromoCard {...props} />;
        case 'conversation':
            return <ConversationPromoCard {...props} />;
        case 'content':
            return <ContentPromoCard {...props} />;
        default:
            return null;
    }
}

function handleScrollTo({scrollRef, scrollViewMesurements, increment = false}) {
    const {x, width, cardWidth} = scrollViewMesurements;
    const position = increment ? Math.max(width, x + cardWidth) : Math.max(0, x - cardWidth);
    scrollRef.scrollTo({x: position, y: 0, animated: true})
}

export function RecirculationSlider({cardsData, cardSize = 'small', controls = false, cardType}) {
    const scrollRef = useRef(null)
    const scrollViewMesurements = useRef({ x: 0, width: 0, cardWidth: 0});
    const s = recirculationSliderStyle;

    if(cardsData === undefined || cardsData?.length === 0) {
        return <></>;
    }

    if (cardsData?.length === 1) {
        cardSize = 'full'
        return (
            <View>
                {getCardComponent({...cardsData[0], cardSize, cardType})}
            </View>
        )
    }

    return (
        <>
            <ScrollView ref={scrollRef} horizontal showsHorizontalScrollIndicator={false}
                onScroll={
                    ({nativeEvent}) => {
                        scrollViewMesurements.current = {...scrollViewMesurements.current, x: nativeEvent.contentOffset.x}
                    }
                }
                scrollEventThrottle={16}
                onLayout={
                    (event) => {
                        const { width } = event.nativeEvent.layout;
                        scrollViewMesurements.current = {...scrollViewMesurements.current, width, cardWidth: Math.round(width / cardsData.length)}
                    }
            }>
                {cardsData.map((props, index) =>
                    <View key={`${cardType}-${props.id}`} style={s.container}>
                        {getCardComponent({...props, cardSize, cardType})}
                        {cardsData?.length !== index + 1 && (
                            <Pad size={spacings.lg} />
                        )}
                    </View>
                )}
            </ScrollView>
            {controls && (
                <>
                    <Pad size={spacings.lg} />
                    <HorizBox spread center>
                        <TextButton label='Previous' leftIcon={ChevronLeft} color={colorTextGrey} onPress={() => handleScrollTo({scrollRef: scrollRef.current,  scrollViewMesurements: scrollViewMesurements.current})} />
                        <TextButton label='Next' rightIcon={ChevronRight} color={colorTextGrey} onPress={() => handleScrollTo({scrollRef: scrollRef.current,  scrollViewMesurements: scrollViewMesurements.current, increment: true})} />
                    </HorizBox>
                </>
            )}

        </>
    )
}

const recirculationSliderStyle = StyleSheet.create({
    container: {
        flexDirection: 'row',
    }
})