
import { View } from "react-native"
import { HorizBox, Pad } from "np-platform-client/component/basics"
import { UtilityText } from "np-platform-client/component/text"
import { usePersonaKey } from "np-platform-client/util/datastore"
import React from "react"
import { colorTextGrey } from "np-platform-client/component/color"
import { getPrettyTimestamp } from "../../../system/videotime"
import { VideoVotingOption } from "./videoVotingQuestionElements"

export function QuestionResultIndividual({
    question,
    allVotes = []
}) {
    const personaKey = usePersonaKey();
    const personalVote = allVotes.find((vote) => (vote.from === personaKey && vote.questionKey === question.key));

    return <View>
        <HorizBox>
            <UtilityText label="Time:" color={colorTextGrey} />
            <Pad size={5} />
            <UtilityText text={getPrettyTimestamp(question.timestamp)} color={colorTextGrey} />
        </HorizBox>
        <Pad size={10} />
        {question.options.map((option, idx) => {
            const votesOfOption = allVotes.filter(v => (v.optionKey === option.key))
            return <View key={idx}>
                <VideoVotingOption
                    optionText={option.text}
                    voteCount={votesOfOption.length}
                    allVoteCount={allVotes.length}
                    selected={personalVote?.optionKey === option.key}
                    voterKeys={votesOfOption.map(v=>v.anonymous ? null : v.from)}
                    showResults
                    />
            </View>
            }
        )}
    </View>
}