import { useEffect, useState } from 'react';
import { HorizBox, Pad, PadBox, TeaserScreen } from "np-platform-client/component/basics";
import { CTAButton } from "np-platform-client/component/button";
import { DataVizText, Heading, Paragraph, UtilityText } from "np-platform-client/component/text";
import { playVideo } from '../../../system/videotime';
import { useCollection, useDatastore, useGlobalProperty, useModulePublicData, usePersonaKey } from 'np-platform-client/util/datastore';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { Banner, ClickableBanner } from 'np-platform-client/component/banner';
import { gotoInstance, gotoInstanceScreen } from 'np-platform-client/util/navigate';
import { VideoPlayerStateContextProvider, useVideo } from '../videoPlayerStateContext';
import { ForceVideoPauseComponent, ArticleMainVideoCopy } from '../videoPlayer';
import { HiddenOverlay, OverlayScreen } from '../overlay';
import { VIDEO_VOTING_SESSION_STATES, setVideoVotingSessionData, updateVideoVotingSessionData } from '../videovoting/videoVotingSession';
import { VideoVotingCountdownElement } from '../countdown';
import { QuestionResultConnected } from '../../../feature/zdf/videovoting/VideoVotingOverviewFeature';
import { VideoVotingOption } from '../videovoting/videoVotingQuestionElements';
import { logEventAsync } from 'np-platform-client/util/eventlog';
import { generateRandomKey } from 'np-platform-client/util/util';
import { Toggle } from 'np-platform-client/component/form';
import { RichText } from 'np-platform-client/component/richtext';
import { useVideoVotingSessionData } from '../videovoting/videoVotingSession';
import { SkipForwardFilled } from '@carbon/icons-react';

/*
Figma: https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-5&t=5DeU2AZDvMXzjf7G-0
*/

export const VideoVotingStructure = {
    key: 'videovoting',
    name: 'Video Voting',
    screen: VideoVotingSidebarScreen,
    teaser: VideoVotingTeaser,
    subscreens: {
    },
    defaultConfig: {
    }
}

function VideoVotingSidebarScreen() {
    return <UtilityText text='No video voting main screen defined.' />
}

export function VideoVotingTeaser() {
    return <TeaserScreen>
        <VideoPlayerStateContextProvider useOriginalVideo={true}>
            <VideoVoting repositionIframe={true}></VideoVoting>
        </VideoPlayerStateContextProvider>
    </TeaserScreen>
}

export function VideoVoting({repositionIframe}) {
    // Set this to true: the original video will be used. Voting elements are shown in the teaser
    // Set this to false: a mirrored video is used with voting elements on top and the original video will be hidden
    const useOriginalVideo = true;

    const currentSession = useVideoVotingSessionData();

    return (<>
        <VideoVotingInitializer></VideoVotingInitializer>
        {currentSession && <>
            <VideoVotingSessionController></VideoVotingSessionController>
            {!useOriginalVideo ?
                <View style={{ position: "relative", backgroundColor: "transparent" }}>
                    <ArticleMainVideoCopy></ArticleMainVideoCopy>
                    <View style={{ position: "absolute", width: "100%", height: "100%", pointerEvents: "none" }}>
                        <VotingWindowPositioner>
                            <VideoVotingScreen></VideoVotingScreen>
                        </VotingWindowPositioner>

                    </View>
                </View>
                :
                <VotingWindowPositioner repositionIframe={repositionIframe}>
                    <VideoVotingScreen></VideoVotingScreen>
                </VotingWindowPositioner>
            }
        </>}
    </>
    );
}

function VideoVotingScreen() {
    const videoVotingTemplate = useGlobalProperty("videoVotingTemplate");
    const datastore = useDatastore();
    const currentSession = useVideoVotingSessionData();

    const onCloseQuestionDialog = () => {
        const question = getQuestionFromTemplate(videoVotingTemplate, currentSession.currentQuestionKey)
        const questionSessionState = currentSession.questions[question.key];
        const updatedQuestions = {...currentSession.questions}
        updatedQuestions[question.key] = {...questionSessionState, answered: true}

        if(question.type === "connected" && question.final) {
            updateVideoVotingSessionData(datastore, {
                state: VIDEO_VOTING_SESSION_STATES.showQuestionResults,
                questions: updatedQuestions
            })
            return
        }

        updateVideoVotingSessionData(datastore, {
            state: !question.final ? VIDEO_VOTING_SESSION_STATES.waitForQuestion : VIDEO_VOTING_SESSION_STATES.endScreen,
            questions: updatedQuestions
        })
    }

    const onCloseQuestionResultsDialog = () => {
        const question = getQuestionFromTemplate(videoVotingTemplate, currentSession.currentQuestionKey)
        updateVideoVotingSessionData(datastore, {
            state: !question.final ? VIDEO_VOTING_SESSION_STATES.waitForQuestion : VIDEO_VOTING_SESSION_STATES.endScreen
        })
    }

    if(!videoVotingTemplate || !currentSession) {
        return <Banner>
            <Heading label={"The video voting template is missing"}></Heading>
        </Banner>
    }

    if(currentSession.state == VIDEO_VOTING_SESSION_STATES.initVideo) {
        return <HiddenOverlay>
            <UtilityText text="Waiting for video to load!"></UtilityText>
        </HiddenOverlay>
    }

    if(currentSession.state == VIDEO_VOTING_SESSION_STATES.initSession){
        return <VideoVotingIntroScreen></VideoVotingIntroScreen>
    }

    if(currentSession.state == VIDEO_VOTING_SESSION_STATES.showQuestionResults){
        const question = getQuestionFromTemplate(videoVotingTemplate, currentSession.currentQuestionKey)
        return <VideoVotingQuestionResultDialog question={question} onFinishQuestionResults={onCloseQuestionResultsDialog}></VideoVotingQuestionResultDialog>
    }

    if(currentSession.state == VIDEO_VOTING_SESSION_STATES.showQuestion){
        const question = getQuestionFromTemplate(videoVotingTemplate, currentSession.currentQuestionKey);

        return (
            <VideoVotingQuestionDialog
                question={question}
                onFinishQuestionVoting={onCloseQuestionDialog}
                headerText={question.subQuestionIndex > 0 ? "What do you think about it now?" : null}
            />
        );
    }

    if(currentSession.state == VIDEO_VOTING_SESSION_STATES.endScreen){
        return <VideoVotingEndScreen></VideoVotingEndScreen>
    }

    return <HiddenOverlay>
        <UtilityText text="No question overlay shown"></UtilityText>
    </HiddenOverlay>
}
const notLoggedInText = "You have to be logged in to use this feature.\nThe video will play as usual and you won't see any votings.\n**Click here to log in**.";

const introText = 'This video contains audience questions that you can participate in. You can vote anonymously, skip individual questions, or disable this feature. In the '
function VideoVotingIntroScreen() {

    const datastore = useDatastore();
    const personaKey = usePersonaKey();
    const session = useVideoVotingSessionData()
    const questionThreadKey = useGlobalProperty("questionThreadKey");
    const video = useVideo();

    const startSession = () => {
        logEventAsync(datastore, 'videovoting-start-video', {
            votingDisabled: votingDisabledLocally.toString(),
            anonymous: anonymityVoting.toString(),
            videoVotingSessionId: session.id
        });

        if (!votingDisabledLocally && personaKey) {
            updateVideoVotingSessionData(datastore, { state: VIDEO_VOTING_SESSION_STATES.waitForQuestion, personaKey: personaKey});
        }
        else {
            updateVideoVotingSessionData(datastore, { state: VIDEO_VOTING_SESSION_STATES.skippedVoting });
            video.playVideo()
        }
    }

    const [anonymityVoting, setAnonymityVoting] = useState(false)
    const setAnonymity = () => {
        const newAnonymityVoting = !anonymityVoting;

        setAnonymityVoting(newAnonymityVoting);
        updateVideoVotingSessionData(datastore, { anonymous: newAnonymityVoting });

        logEventAsync(datastore, 'videovoting-change-anonymity', {
            anonymous: newAnonymityVoting.toString(),
            videoVotingSessionId: session.id
        });
    }

    const [votingDisabledLocally, setVotingDisabledLocally] = useState(false);
    const toggleVideoVoting = () => {
        const newVotingDisabledLocally = !votingDisabledLocally;

        setVotingDisabledLocally(newVotingDisabledLocally);

        logEventAsync(datastore, 'videovoting-change-local-setting', {
            votingDisabledLocally: newVotingDisabledLocally.toString(),
            videoVotingSessionId: session.id
        });
    }

    const gotoUserSettings = () => {
        gotoInstance({structureKey: "profile", instanceKey: personaKey})
        logEventAsync(datastore, 'videovoting-navigate-to-user-settings', {
            from: "intro-screen",
            videoVotingSessionId: session.id
        })
    }

    const s = IntroScreenStyle;

    return <View style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <ForceVideoPauseComponent></ForceVideoPauseComponent>
        <View style={{backgroundColor: "white", pointerEvents:"auto"}}>
            <PadBox horiz={32} vert={32}>
                <Heading label={"Video Voting"}></Heading>
                <Pad size={24}></Pad>
                <Text>      
                <Paragraph label={introText}></Paragraph>
                <Pressable onPress={gotoUserSettings}>
                    <Paragraph strong label={"settings"}></Paragraph>
                </Pressable>
                <Paragraph label={' you can deactivate it for all videos.'}></Paragraph>
                </Text>

                <Pad size={24}></Pad>
                
                {personaKey ? <div style={s.optionsContainer}>
                    <Toggle spread label={"Enable voting"} value={!votingDisabledLocally} onChange={toggleVideoVoting}/>

                    <View style={{
                        opacity: !votingDisabledLocally ? 1 : 0,
                        cursor: !votingDisabledLocally ? "auto" : "default"
                        }}>
                        <Pad size={10}/>
                            <Toggle spread label={"Vote anonymously"} value={anonymityVoting} onChange={setAnonymity}/>
                    </View>
                </div> :
                <View>
                    <ClickableBanner onPress={()=>{gotoInstanceScreen({structureKey: "question", instanceKey: questionThreadKey, screenKey:'login', params:{}})}}>
                        <RichText label={notLoggedInText}/>
                    </ClickableBanner>
                </View>}

                <Pad size={40}></Pad>
                <CTAButton label={"Start Video"} onPress={startSession}></CTAButton>  
            </PadBox>  
        </View>
    </View>
}

const IntroScreenStyle = StyleSheet.create({
    optionsContainer: {
        width: "fit-content"
    }
});

export function VideoVotingQuestionDialog({question, onFinishQuestionVoting, headerText = null, timer = 60}) {

    const datastore = useDatastore();
    const personaKey = usePersonaKey();
    const session = useVideoVotingSessionData()
    const questionThreadKey = useGlobalProperty("questionThreadKey");

    // Keeps track of whether the user interacts with the voting options while this dialog is visible. Voting or revoking a vote both count as interaction. Users can revoke their votes by clicking the option they voted for a second time.
    const [userInteractedWithOptions, setUserInteractedWithOptions] = useState(false);

    let allVotes = useCollection("votes");
    // Get all votes for this question, then filter out revoked votes.
    // TODO: We should probably implement a way to delete revoked votes so they don't stay in the database.
    allVotes = allVotes.filter(vote => (vote.questionKey === question.key)).filter(vote => (vote.optionKey));

    const ownVote = allVotes.find((vote) => (vote.from === personaKey && vote.questionKey == question.key));

    const voteForOption = async (option) => {
        setUserInteractedWithOptions(true);

        if (!ownVote) {
            datastore.addObject("votes", {
                optionKey: option.key,
                questionKey: question.key,
                anonymous: session?.anonymous ? session.anonymous : false,
            });

            logEventAsync(datastore, "videovoting-vote", {
                questionKey: question.key,
                questionText: question.text,
                optionKey: option.key,
                optionText: option.text,
                anonymous: session?.anonymous?.toString(),
                videoVotingSessionId: session.id,
            });
    
        } else {
            datastore.updateObject("votes", ownVote.key, {
                optionKey: option.key === ownVote.optionKey ? null : option.key,
                questionKey: question.key,
                anonymous: session.anonymous ? session.anonymous : false,
            });

            // User has clicked the same option again, thus revoking their vote
            if (option.key === ownVote.optionKey) {
                logEventAsync(datastore, "videovoting-revoke-vote", {
                    questionKey: question.key,
                    questionText: question.text,
                    optionKey: option.key,
                    optionText: option.text,
                    anonymous: session.anonymous.toString(),
                    videoVotingSessionId: session.id,
                });
            }
            // User casts a valid vote
            else {
                logEventAsync(datastore, "videovoting-vote", {
                    questionKey: question.key,
                    questionText: question.text,
                    optionKey: option.key,
                    optionText: option.text,
                    anonymous: session.anonymous.toString(),
                    videoVotingSessionId: session.id,
                });
            }
        }
    };

    const openDiscussion = () => {
        try {
            logEventAsync(datastore, 'videovoting-navigate-to-discussion', {
                from: "question-dialog",
                questionKey: question.key,
                questionText: question.text,
                videoVotingSessionId: session.id
            });
    
            gotoInstance({ structureKey: "question", instanceKey: questionThreadKey });
        } catch (error) {
            console.log("Failed to open discussion.");
        }
        
    }

    const onPressContinueButton = () => {
        try {
            logEventAsync(datastore, "videovoting-skip-remaining-time", {
                questionKey: question.key,
                questionText: question.text,
                interactedWithOptions: userInteractedWithOptions.toString(),
                ownVoteExists: (ownVote !== null && ownVote !== undefined).toString(),
                videoVotingSessionId: session.id,
            });
            onFinishQuestionVoting(question);
        } catch (error) {
            console.log("Failed to skip remaining time.");
        }

    }

    const onTimeUp = () => {
        try {
            logEventAsync(datastore, "videovoting-time-up", {
                questionKey: question.key,
                questionText: question.text,
                interactedWithOptions: userInteractedWithOptions.toString(),
                ownVoteExists: (ownVote !== null && ownVote !== undefined).toString(),
                videoVotingSessionId: session.id,
            });
            onFinishQuestionVoting(question);
        } catch (error) {
            console.log("Failed to continue after time was up.");
        }
    }

    return (
        <SkippableDiscussContainer
            skipAction={onPressContinueButton}
            timeUpAction={onTimeUp}
            openDiscussion={openDiscussion}
            timer={timer}
        >
            <ForceVideoPauseComponent />
            <View style={{gap: 8}}>
                {headerText && <DataVizText type={"heading2"} label={headerText}/>}
                <Heading text={question.text} />
            </View>      
            <Pad size={24} />
            {question.options.map((item, index) => {
                const votesOfOption = allVotes.filter((v) => v.optionKey === item.key);
                return (
                    <VideoVotingOption
                        key={"videovotingoption-" + index}
                        votingEnabled
                        optionText={item.text}
                        selected={ownVote?.optionKey === item.key}
                        showResults={userInteractedWithOptions || (ownVote !== null && ownVote !== undefined)}
                        voterKeys={votesOfOption.map((v) => (v.anonymous ? null : v.from))}
                        voteCount={votesOfOption.length}
                        allVoteCount={allVotes.length}
                        onSelect={() => voteForOption(item)}
                    />
                );
            })}
        </SkippableDiscussContainer>
    );
}

export function VideoVotingEndScreen() {
    const questionThreadKey = useGlobalProperty("questionThreadKey");
    const datastore = useDatastore();
    const session = useVideoVotingSessionData();

    const goToOverview = () => {
        logEventAsync(datastore, 'videovoting-navigate-to-discussion', {
            from: "end-screen",
            videoVotingSessionId: session.id
        })
        gotoInstanceScreen({structureKey: "question", instanceKey: questionThreadKey, screenKey: null, params: {tab: "tab-voting"}})
    }

    return <View>
        <ForceVideoPauseComponent></ForceVideoPauseComponent>
        <CTAButton wide label={"Take a look at the voting in the overview"} onPress={goToOverview}></CTAButton>
    </View>
}

function findQuestionForTimestamp(timestamp, templateQuestions) {
    for (let i = 0; i < templateQuestions.length; i++) { 
        const question = templateQuestions[i];

        // Skip final questions
        if(question.final) continue

        if(question.type === "connected") {
            for (let j = 0; j < question.subQuestions.length; j++) {
                const subQuestion = question.subQuestions[j];

                // Skip final questions
                if(subQuestion.final) continue

                if(timestamp >= subQuestion.timestamp) {
                    if (j === question.subQuestions.length - 1 || question.subQuestions[j + 1].timestamp > timestamp) {
                        // Add the options to the question Object to be returned
                        const returnQuestion = {
                            ...question,
                            ...subQuestion,
                        }
                        return returnQuestion;
                    }
                }
            }
            continue;
        }

        if(timestamp >= question.timestamp) {
            // Timestamp must no be after the timestamp of the next question
            if (i === templateQuestions.length - 1 || templateQuestions[i + 1].timestamp > timestamp) {
                return question;
            }
        }
    }
    return null;
}

function getQuestionFromTemplate(template, questionKey) {
    for (const question of template.questions) {
        if (question.key === questionKey) {
            return question;
        }

        // If Question is connected, search the subquestions
        if (question.type === "connected") {
            const foundSubQuestion = question.subQuestions.find((subQuestion) => subQuestion.key === questionKey);
            
            if (foundSubQuestion) {
                const subQuestionIndex = question.subQuestions.findIndex((subQuestion) => subQuestion.key === questionKey);

                return {
                    ...question,
                    ...foundSubQuestion,
                    subQuestionIndex
                };
            }
        }
    }
}

function getFinalQuestionFromTemplate(template) {
    for(let i = 0; i < template.questions.length; i++) {
        const question = template.questions[i];

        // If Question is connected, search the subquestions
        if(question.type === "connected") {
            const foundSubQuestion = question.subQuestions.find(subQuestion => (subQuestion.final === true))
            if(foundSubQuestion) {
                return foundSubQuestion;
            }
            continue
        }

        if(question.final){
            return question
        }
    }
}

function VideoVotingQuestionResultDialog({question, onFinishQuestionResults}) {
    const session = useVideoVotingSessionData();
    const datastore = useDatastore();
    const questionThreadKey = useGlobalProperty("questionThreadKey");

    let allVotes = useCollection("votes");

    let results = <></>;
    if(question.type === "connected"){
        const firstConnectedQustionFromTemplate = session.template.questions.find(q => q.type === "connected")
        const allSubQuestionKeys = firstConnectedQustionFromTemplate.subQuestions.map(q=>q.key)
        allVotes = allVotes.filter(vote => (vote.optionKey && allSubQuestionKeys.includes(vote.questionKey)));
        results = <QuestionResultConnected question={firstConnectedQustionFromTemplate} publicVotes={allVotes} showTotalVotes></QuestionResultConnected>
    }

    const openDiscussion = () => {
        logEventAsync(datastore, 'videovoting-navigate-to-discussion', {
            from: "question-result-dialog",
            questionKey: question.key,
            questionText: question.text,
            videoVotingSessionId: session.id
        });

        gotoInstance({ structureKey: "question", instanceKey: questionThreadKey });
    }
    
    return <SkippableDiscussContainer timeUpAction={onFinishQuestionResults} openDiscussion={openDiscussion} skipManually={false}>
        <ForceVideoPauseComponent></ForceVideoPauseComponent>
        {results}
    </SkippableDiscussContainer>
}

function SkippableDiscussContainer({ children, skipAction, timeUpAction, openDiscussion, timer = 60, skipManually = true }) {
    return <View style={{backgroundColor: "white"}}>
        <View>
            {(timeUpAction && timer) && <View style={{ position: "absolute", right: 0 }}>
                <VideoVotingCountdownElement size={48} circleRadius={18} strokeWidth={4} timer={timer} callback={timeUpAction}></VideoVotingCountdownElement>
            </View> }      
            <PadBox horiz={32} vert={32}>
                {children}
                <Pad size={24}></Pad>
                <HorizBox center spread>
                    <CTAButton label={"Discuss with others"} onPress={openDiscussion}></CTAButton>
                    {(skipAction && skipManually) && <CTAButton icon={<SkipForwardFilled/>} type='secondary' label={"Continue"} onPress={skipAction}></CTAButton>}
                </HorizBox>
            </PadBox> 
        </View>        
    </View>
}

// This container handles that each voting window has its correct position and size
function VotingWindowPositioner({children, repositionIframe}) {
    const videoVotingSession = useVideoVotingSessionData();

    let position = {left: "25%", top: "10%"};
    let dimension = {width:"50%"};
    let showBackdrop = false

    // TODO: Later we can add custom positioning and sizing for individual questions here
    if(videoVotingSession.state === VIDEO_VOTING_SESSION_STATES.showQuestion){
        position = {right: "50px", left: "", top: "50px"}
        showBackdrop = true
    }

    if(videoVotingSession.state === VIDEO_VOTING_SESSION_STATES.endScreen){
        dimension={width: "30%"} 
        position={left: "35%", top: "45%"}
        showBackdrop = true
    }

    if(videoVotingSession.state === VIDEO_VOTING_SESSION_STATES.showQuestionResults){
        position = {right: "50px", left: "", top: "50px"}
        dimension = {width:"432px"}
        showBackdrop = true
    }

    if(videoVotingSession.state === VIDEO_VOTING_SESSION_STATES.initSession) {
        showBackdrop=true
    } 
    return <OverlayScreen position={position} dimension={dimension} repositionIframe={repositionIframe} showBackdrop={showBackdrop}>
        {children}
    </OverlayScreen>
}

// This component sets up the video voting session data
// It handles cleanups and resets in case the user changes, etc
function VideoVotingInitializer({children}){
    const datastore = useDatastore()
    const videoVotingSession = useVideoVotingSessionData();
    const videoVotingTemplate = useGlobalProperty("videoVotingTemplate");
    const personaKey = usePersonaKey()

    let votingDisabledGlobally = useModulePublicData("videovoting", ["disabledForUser", personaKey]);
    if (votingDisabledGlobally === null || votingDisabledGlobally === undefined) {
        votingDisabledGlobally = false;
    }

    const initializeSession = () => {
        const initialSessionData = {
            state: votingDisabledGlobally ? VIDEO_VOTING_SESSION_STATES.disabled : VIDEO_VOTING_SESSION_STATES.initVideo,
            anonymous: false,
            questions: {},
            personaKey: personaKey,
            template: videoVotingTemplate,
            id: generateRandomKey(10),
        }
        setVideoVotingSessionData(datastore, initialSessionData)
    };

    useEffect(() => {
        // Initialize with an empty session
        // When the user changes, we have to reset the session
        if (videoVotingTemplate && (!videoVotingSession || (videoVotingSession.personaKey && videoVotingSession.personaKey != personaKey))) {   
            initializeSession()
            return
        }

        // Case: user changes or the global setting changes -> Reset session
        if(videoVotingSession &&
            (videoVotingSession.personaKey != personaKey || 
                votingDisabledGlobally && videoVotingSession.state !== VIDEO_VOTING_SESSION_STATES.disabled || 
                !votingDisabledGlobally && videoVotingSession.state === VIDEO_VOTING_SESSION_STATES.disabled
            )
        ) {
            initializeSession()
        }
    }, [videoVotingSession, personaKey, videoVotingTemplate, votingDisabledGlobally]);

    return <>{children}</>
}


// This component handles the control flow of the video voting
// It reacts to session changes 
function VideoVotingSessionController({children}){
    const datastore = useDatastore()
    const videoVotingSession = useVideoVotingSessionData();
    const videoVotingTemplate = useGlobalProperty("videoVotingTemplate");
    const video = useVideo();

    const checkForQuestion = () => {
        // show final question when video ended or video is close to the end
        if(video.ended || video.currentTime > video.duration - 20) {
            const finalQuestion = getFinalQuestionFromTemplate(videoVotingTemplate)
            if(finalQuestion && !videoVotingSession.questions[finalQuestion.key]?.answered) {
                updateVideoVotingSessionData(datastore, {currentQuestionKey: finalQuestion.key, state: VIDEO_VOTING_SESSION_STATES.showQuestion})
                video.pauseVideo()
                return true
            }
        }

        const nextQuestion = findQuestionForTimestamp(video.currentTime, videoVotingTemplate.questions)
        if(nextQuestion && !videoVotingSession.questions[nextQuestion.key]?.answered) {
            updateVideoVotingSessionData(datastore, {currentQuestionKey: nextQuestion.key, state: VIDEO_VOTING_SESSION_STATES.showQuestion})
            video.pauseVideo()
            return true
        }
        return false
    }

    // State machine: React to changing states
    useEffect(() => {
        if(videoVotingSession.state === VIDEO_VOTING_SESSION_STATES.waitForQuestion) {
            if(checkForQuestion()){
                // Transition to next question happens seamlessly, without playing the video
                return;
            } else {
                video.playVideo()
            }
        }

        if(videoVotingSession.state === VIDEO_VOTING_SESSION_STATES.initVideo && video.videoLoaded) {
            updateVideoVotingSessionData(datastore, { state: VIDEO_VOTING_SESSION_STATES.initSession });
        }
    }, [videoVotingSession.state, video.videoLoaded])

    // Check if the timestamp for the next question is reached
    useEffect(() => {
        if(videoVotingSession?.state === VIDEO_VOTING_SESSION_STATES.waitForQuestion){
            checkForQuestion()
        }
    }, [video.currentTime, video.ended])

    return<>{children}</>
}