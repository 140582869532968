import React from "react";
import { View } from "react-native";
import { useCollection, useDatastore } from "np-platform-client/util/datastore";
import { useConfig } from "np-platform-client/util/features";
import { HorizBox, PadBox } from "np-platform-client/component/basics";
import { Catcher, CatchList } from "np-platform-client/system/catcher";
import { colorTeaserBackground, colorLightBlueBackground } from "np-platform-client/component/color";
import { Comment } from "np-platform-client/component/comment";
import { RichText } from "np-platform-client/component/richtext";
import { Banner } from "np-platform-client/component/banner";

export function REPLACE_CBC_PopularComments({about=null, showInput=true, canPost=true}) {
    const {noCommentsMessage} = useConfig();
    const comments = useCollection('comment', {filter: {about, replyTo: null}, sortBy: 'time', reverse: true})
    const upVotes = useCollection('upvote');

    const topComments = comments.filter((comment) => upVotes.some((upVote) => upVote.comment === comment.key));
    const otherComments = comments.filter((comment) => !upVotes.some((upVote) => upVote.comment === comment.key));
    const popularComments = [...topComments, ...otherComments].slice(0,4);

    return (
        <View>
            {comments?.length == 0 &&
                <PadBox vert={20} horiz={20}><Banner color={colorTeaserBackground}><RichText label={noCommentsMessage} /></Banner></PadBox>
            }
            <CatchList items={popularComments} renderItem={comment => <Comment commentKey={comment.key} />} />
        </View>
    )
}