import { Pad } from "np-platform-client/component/basics";
import { CTAButton } from "np-platform-client/component/button";
import { TextField } from "np-platform-client/component/text";
import { useDatastore, useModulePublicData } from "np-platform-client/util/datastore";
import { useState } from "react";
import { View } from "react-native";

export function AddQuestionBox({onAdded, onChange}) {
    const [question, setQuestion] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const datastore = useDatastore();

    async function onAdd() {
        setInProgress(true);
        const result = await datastore.callServerAsync('questions', 'add', {question});
        onAdded && onAdded(result.questionKey);
        setInProgress(false);
        setQuestion('');
        onChange && onChange('');
    }

    return <View>
        <TextField autoFocus value={question} onChange={text => {setQuestion(text); onChange && onChange(text)}}
            testID='add-question' label='Ask a question' placeholder='Ask a question...' />
        {(question || inProgress) && <Pad />}
        {inProgress ? 
            <CTAButton disabled label='Adding...' />
        : question ?
            <CTAButton label='Add Question' onPress={onAdd} />
        : null
        }
    </View>
}

export function useGlobalQuestions() {
    const questionObjs = useModulePublicData('question');
    const keys = Object.keys(questionObjs || {});
    const questions = keys.map(key => ({key, ...questionObjs[key]}));
    return questions;
}
