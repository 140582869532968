import { CommentSliderComponentsDemo, CommentSliderFullDemo, commentSliderStorySets } from "./commentsliderdemo"
import { ReactionTypesComponentDemo, ReactionTypesFullDemo, reactionTypesStorySets } from "./reactiontypesdemo"
import { RepresentingPerspectivesComponentsDemo, RepresentingPerspectivesFullDemo, representingPerspectivesStorySets } from "./representingperspectivesdemos"
import { ContextEntryStorySets } from "./contextentrydemo"
import { communityGuidelinesStorySets } from "./communityguidelines-demo"

export const DemoConversationFeatures = {
    key: 'demo_conversation',
    name: 'Demo - Conversation',
    config: {
        componentSections: [
            {label: 'Conversation', key: 'coversation', pages: [
                {
                    label: 'Comment Slider', 
                    key: 'commentslider', 
                    screen: CommentSliderComponentsDemo,
                    designUrl: 'https://www.figma.com/design/SgBNOg9PPIKIsSMAmU32rK/Comments-Slider---Testing?node-id=620-7567&m=dev',
                    storySets: commentSliderStorySets
                },
                {
                    label: 'Representing Perspectives', 
                    key: 'representingperspectives', 
                    screen: RepresentingPerspectivesComponentsDemo,
                    storySets: representingPerspectivesStorySets,
                    designUrl: 'https://www.figma.com/design/3vGCewqJoClk1vyiptYeon/Representing-Perspectives---Testing?node-id=898-1334&m=dev'
                },
                {
                    label: 'Reaction Types', 
                    key: 'reactiontypes', 
                    // screen: ReactionTypesComponentDemo,
                    storySets: reactionTypesStorySets,
                    designUrl: 'https://www.figma.com/design/S8vgvD8EmLj9JbvMhP5oD2/Reaction-Types---Testing?node-id=70-1797&m=dev'
                },
                {
                    label: 'Context Entry Points', 
                    key: 'contextentrypoints', 
                    storySets: ContextEntryStorySets,
                    designUrl: 'https://www.figma.com/design/yhR3yi5zcArGh8odsd3pfh/Cross-Content-Threads-%26-Context-Entry-Points---Testing?node-id=720-17571&node-type=canvas&t=ib6qYn2DTZam6kyV-0'
                },
                {
                    label: 'Community Guidelines', 
                    key: 'community_guidelines',
                    designUrl: 'https://www.figma.com/design/qbH103GBR3McZqhUp7O9fA/Onboarding-%26-Login-%26-Community-Guidelines---Testing?node-id=0-1&node-type=canvas&t=DtLdd9yzAlEsyXKd-0',
                    storySets: communityGuidelinesStorySets
                }                
            ]},
        ],
        featureSections: [
            {label: 'Question', key: 'conversationfeatures', pages: [
                {label: 'Comment Slider', key: 'commentsliderfull', screen: CommentSliderFullDemo},
                {label: 'Representing Perspectives', key: 'representingperspectivesfull', screen: RepresentingPerspectivesFullDemo},
                {label: 'Reaction Types', key: 'reactiontypesfull', screen: ReactionTypesFullDemo},
            ]},
        ],
    },
}

