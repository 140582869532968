import { ActionUpvote } from "np-platform-client/feature/UpvoteFeature"
import { ActionReportComment, ReportPopup } from "../feature/moderation/ReportCommentFeature"
import { ActionReply, Comment, Composer } from "np-platform-client/component/comment"
import { CLICK, INPUT, POPUP, CHECK_TEXT } from "np-platform-client/system/demo"
import { ModerationModal, ModerationReport } from "../feature/moderation/AIPreModeratorFeature"
import { assembleConfig } from "np-platform-client/util/features"
import { QuestionStructure } from "../structure/question"
import { GUIDELINES } from "../feature/question/CommunityGuidelinesFeature"

export const DemoModerationFeature = {
    key: 'demo_moderation',
    name: 'Demo - Moderation Features',
    config: {
        componentSections: [
            {label: 'Moderation', key: 'moderation', pages: [
                {
                    label: 'Report Comment', key: 'report_comment', 
                    designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=5220-2966&t=dlCMQ4bQ0Ij7PDHZ-1', 
                    storySets: reportCommentStorySets
                },
                {
                    label: 'AI Pre-Moderation', key: 'ai_premoderation',
                    designUrl: 'https://www.figma.com/design/F9OCIwB0YSBYvH0TTJ4LbP/AI-Moderation---Testing?node-id=525-4049&node-type=canvas&t=72IdegenOfhw86hI-0',
                    storySets: aiPreModerationStorySets
                },
                {
                    label: 'Admin Remove', key: 'admin_remove',
                    storySets: adminRemoveStorySets,
                }
            ]},
        ]
    }
}

const collections = {
    comment: [
        {key: 1, from: 'b', text: 'People who disagree with me should be tortured.'},
    ]
}

const config = {
    commentActions: [ActionReply, ActionUpvote],
    commentRightActions: [ActionReportComment]
}

const serverCall = {moderation: {
    sendToReview: () => {}
}}


function reportCommentStorySets() {return [
    {
        label: 'Report Comment',
        collections, config, serverCall,
        content: <Comment commentKey={1} />,
        stories: [
            {
                label: 'Report Comment', actions: [
                CLICK('report'), 
                POPUP(CLICK(GUIDELINES.NO_UNCIVIL)), 
                POPUP(CLICK(GUIDELINES.NO_HARM)), 
                POPUP(CLICK('Report'))
            ]}
        ]
    },
    {
        label: 'Report Popup',
        collections, config, serverCall,
        content: <ReportPopup commentKey={1} onClose={() => {}} />,
        stories: [
            {label: 'Report Comment', actions: [
                CLICK(GUIDELINES.NO_SPAM), 
                CLICK('Report')
            ]}
        ]
    }
]}

const comment = {
    text: 'People who disagree with me should be tortured.',
    key: 1,
    violates: true,
    violations: ['Avoid talking about vegetables'],
}

const commentInReview = {
    text: 'Vegetables are gross.',
    key: 2,
    violates: true,
    violations: ['Avoid talking about vegetables'],
    inReview: true
}

const commentWithHumanJudgement = {
    text: 'Vegetables are healthy.',
    key: 3,
    violates: true,
    violations: ['Avoid talking about vegetables'],
    humanJudgement: true
}

function aiPreModerationStorySets() {return [
    {
        label: 'Moderation Report',
        content: <ModerationReport comment={comment} onCancel={() => {}} />,
        serverCall: {moderation: {sendToReview: () => {}}},
        stories: [
            {label: 'Appeal', actions: [
                CLICK('Send to moderator'),
                POPUP(INPUT('appeal', 'This was counterspeech. They were mean first.')),
                POPUP(CLICK('Send to moderator')),
                POPUP(CLICK('close-modal'))
            ]},
            {label: 'Check Community Guidelines', actions: [
                CLICK('Community Guidelines'),
                POPUP(CLICK('close-modal')),
            ]}            

        ]
    },
    {
        label: 'Moderation of Violating Comment in Review',
        collections: {comment: [commentInReview]},
        content: <Composer commentKey={2} />,
        structureKey: 'question',
        serverCall: {moderation: {
            checkCommentWithGpt: () => ({violates: true, rulesBroken: [2]}),
        }},
        features: ['premoderation', 'aimoderation', 'hide_rejected'],
        stories: [
            {label: 'Can Send To Moderator After Updating Comment', actions: [
                CHECK_TEXT("🔍 Under Review"),
                CHECK_TEXT(/Sent to moderator/),
                INPUT('comment-edit', `${commentInReview.text} - edited but still violates community guidelines`), 
                CLICK('Update'),
                CLICK('Send to moderator'),
            ]},
        ]
    },
    {
        label: 'Violating Comment With Human Judgement',
        collections: {comment: [commentWithHumanJudgement]},
        content: <Composer commentKey={3} />,
        structureKey: 'question',
        features: ['hide_rejected'],
        stories: [
            {label: 'Composer shows rejected tag', actions: [
                CHECK_TEXT("🚫 Rejected by Human Moderator"),             
            ]},
        ]
    },
    {
        label: 'Moderate with GPT and Jigsaw',
        content: <Composer />,
        structureKey: 'question',
        features: ['premoderation', 'aimoderation', 'jigsawmoderation'],
        serverCall: {moderation: {
            checkCommentWithGpt: ({text}) => ({violates: text.includes('Elvis'), rulesBroken: [7, 4]}),
            checkCommentWithJigsaw: ({text}) => ({violates: text.includes('silly') ? true : false})  
        }},
        stories: [
            {label: 'Moderate with GPT', actions: [
                INPUT('comment-edit', 'Elvis killed Kennedy because he was a lizard person'), 
                CLICK('Post'), 
            ]},
            {label: 'Moderate with Jigsaw', actions: [
                INPUT('comment-edit', 'You are silly'), 
                CLICK('Post'), 
            ]},
            {label: 'Good Comment', actions: [
                INPUT('comment-edit', 'I like cats'), 
                CLICK('Post'), 
            ]}
        ]
    },
    {
        label: 'Moderate Replies Strictly',
        collections: {comment: [
            {key: 'a', from: 'b', text: 'I like cats'},
        ]},
        content: <Comment commentKey='a' />,
        structureKey: 'question',
        serverCall: {moderation: {
            moderateReplyStrictly: ({text}) => ({allow: !text.includes('idiot')}),
        }},
        features: ['premoderation', 'strictreplymoderation'],
        stories: [
            {label: 'Good Reply', actions: [
                CLICK('Reply'),
                INPUT('comment-edit', 'I like cats'), 
                CLICK('Post'), 
            ]},
            {label: 'Bad Reply', actions: [
                CLICK('Reply'),
                INPUT('comment-edit', 'Only idiots like cats'), 
                CLICK('Post'), 
            ]}
        ]
    }
]}

function adminRemoveStorySets() {return [
    {
        label: 'Admin Remove Comment',
        collections: {comment: [{key: 1, from: 'c', text: 'This is a bad comment'}]},
        content: <Comment commentKey={1} />,
        structureKey: 'question',
        features: ['admin_remove'],
        roles: ['Moderator'],
        serverCall: {moderation: {
            adminSetCommentViolates: () => {}
        }},
        stories: [
            {label: 'Remove Comment', actions: [
                CLICK('remove'), 
            ]}
        ]
    }
]}