import { UserMultiple } from "@carbon/icons-react";
import { HorizBox, Pad, PadBox, spacings } from "np-platform-client/component/basics";
import { colorWhite } from "np-platform-client/component/color";
import { UtilityText } from "np-platform-client/component/text";
import { useGlobalProperty } from "np-platform-client/util/datastore";

export const FakeTopicParticipantsFeature = {
    key: 'faketopicparticipants',
    name: 'Fake Topic Participants',
    config: {
        topicHeaderBottomWidgets: [FakeTopicParticipantsWidget]
    }
}

export function FakeTopicParticipantsWidget() {
    const count = useGlobalProperty('participantCount') ?? 5;
    
    return <PadBox top={24}>
        <HorizBox>
            <UserMultiple color={colorWhite} />
            <Pad size={spacings.xs} />
            <UtilityText color={colorWhite} label='{count} {noun}' 
                formatParams={{count, singular: 'participant', plural: 'participants'}} 
            />
        </HorizBox>
    </PadBox>
}
